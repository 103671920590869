/* eslint-disable no-unused-vars */
import managementApi from '@/api/managementApi';
import productionApi from '@/api/productionApi';
import settlementApi from '@/api/settlementApi';

let getOABUFilteredByFrontParametersCounter = 0;
let getOABUFilteredByFrontParametersController = new AbortController();
let getOABURFilteredByFrontParametersCounter = 0;
let getOABURFilteredByFrontParametersController = new AbortController();
let GetOldActivitiesFilteredByFrontParametersCounter = 0;
let GetOldActivitiesFilteredByFrontParametersController = new AbortController();
let getWorkflowsFilteredByFrontParametersCounter = 0;
let getWorkflowsFilteredByFrontParametersController = new AbortController();
let getCasesFilteredByFrontParametersCounter = 0;
let getCasesFilteredByFrontParametersController = new AbortController();

export const createOperativeExpenses = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(`Expenses`, dataToSend);
    commit('setGuidBillCreated', data.data);
    commit('management/resultPut', data, { root: true });
};

export const GetExpensesByEntityIdArray = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `Expenses/GetExpensesByEntityIdArray`,
        dataToSend,
    );
    return data.data;
};

export const createOperativeActivityByUser = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers`,
        dataToSend,
    );
    commit('setGuidOperativeActivityCreated', data.data);
    return data;
};

export const getOperativeWorkflowsById = async (
    { commit },
    operativeWorkflowId,
) => {
    const { data } = await productionApi.get(
        `OperativeWorkFlows/${operativeWorkflowId}`,
    );
    commit('setOperativeWorkflow', data.data);
    commit(
        'setWorkFlowsCreateOrEditObjectDefinitionProperty',
        data.data.definition,
    );
    commit(
        'setWorkFlowsCreateOrEditObjectActivitiesProperty',
        data.data.activities,
    );
    return data.data;
};
export const getOperativeWorkflowsDataById = async (
    { commit },
    operativeWorkflowId,
) => {
    const { data } = await productionApi.get(
        `OperativeWorkFlows/${operativeWorkflowId}`,
    );
    return data.data;
};
export const getOperativeActivityByUserId = async (
    { commit },
    [OperativeActivityId, makeCommit],
) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/${OperativeActivityId}`,
    );
    if (makeCommit) {
        commit('setOperativeActivityVal', data.data);
    }
    return data.data;
};

export const getOperativeActivityByUserIdBucle = async (
    { commit },
    [bucleActivityTF, makeCommit],
) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/Bucle/${bucleActivityTF.idBucle}/${bucleActivityTF.idWorkFlow}`,
    );
    if (makeCommit) {
        commit('setOperativeActivityVal', data.data);
    }
    return data.data;
};
export const createOperativeActivityByIdBucle = async (
    { commit },
    dataToSend,
) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/Bucle`,
        dataToSend,
    );
    return data.data;
};

export const getNotificationsByUser = async ({ commit }, UserId) => {
    const { data } = await productionApi.get(
        `OperativeNotifications/GetNotificationByUserId/${UserId}`,
    );
    commit('setNotificationsByUser', data.data);
    return data.data;
};

export const getOperativeActivityById = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/ActivitiesByUserArray`,
        dataToSend,
    );
    commit('activitiesForFlow', data.data);
    commit('setReadyDiagram', true);
    return data.data;
};
export const createOperativeWorkflows = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(`OperativeWorkFlows`, dataToSend);
    commit('setOperativeWorkflowId', data.data);
};

export const updateOperativeWorkflows = async ({ commit }, dataToSend) => {
    const data = await productionApi.put(`OperativeWorkFlows`, dataToSend);
    return data.data;
};

export const updateOperativeActivityByUser = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.put(
        `OperativeActivitiesByUsers/update/${dataToSend.typeBillableTime}`,
        dataToSend,
    );
    return data?.data;
};

export const updateWork = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.put(`OperativeWorks`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data.data;
};

export const createTradeAgreementProduction = async (
    { commit },
    dataToSend,
) => {
    const { data } = await managementApi.post(
        `TradeAgreement/CreateTradeAgreementProductionModule`,
        dataToSend,
    );
    commit('setOperativeWorkflowTradeAgreementId', data.data);
};

export const shareActivity = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/ShareActivity`,
        dataToSend,
    );
    return data.data;
};
export const shareBillingNotes = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `BillingNote/ShareBillingNote`,
        dataToSend,
    );
    return data.data;
};

export const createOperativeActivityToOperativeWokflowArray = async (
    { commit },
    dataToSend,
) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/CreateOperativeActivitiesToOperativeWorkflow`,
        dataToSend,
    );
    return data.data;
};

export const updateTradeAgreementIdIntoOperativeActivity = async (
    { commit },
    { operativeActivityId, newTradeAgreementId },
) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/UpdateTradeAgreementOfOperativeActivity/${operativeActivityId}/${newTradeAgreementId}`,
    );
};

export const getUsersResponsible = async ({ commit }, infoUsers) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/GetUsersResponsible`,
        infoUsers,
    );
    commit('setUsersResponsible', data.data);
    return data.data;
};
export const createWork = async ({ commit }, infoWork) => {
    const { data } = await productionApi.post(`OperativeWorks`, infoWork);
    // commit('setWorkIdCreated', data.data);
};

export const getTradeAgreementIdByProjectId = async ({ commit }, projectId) => {
    const { data } = await managementApi.post(
        `Proyects/GetTradeAgreementIdByProjectId/${projectId}`,
    );
    commit(
        'management/setParamsTradeAgreement',
        {
            id: data.data,
            type: 'edit',
        },
        { root: true },
    );
    commit('setOperativeWorkflowTradeAgreementId', data.data);
};

export const createOperativeDynamicForm = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.post(
        `OperativeDynamicForm`,
        dataToSend,
    );
    commit('setDynamicFormOperativeWorkflows', data.data);
};
export const updateOperativeDynamicForm = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.put(
        `OperativeDynamicForm`,
        dataToSend,
    );
};

export const updateActivitiesProcess = async ({ commit }, dataToSend) => {
    await productionApi.put(`Process/UpdateActivitiesByProcess`, dataToSend);
};

export const getOperativeDynamicFormById = async (
    { commit },
    operativeDynamicFormId,
) => {
    const { data } = await productionApi.get(
        `OperativeDynamicForm/${operativeDynamicFormId}`,
    );
    commit('setOperativeDinamycForm', data.data);
};

export const getOperativeDynamicFormByIdWithAnswers = async (
    { commit },
    operativeDynamicFormId,
) => {
    const { data } = await productionApi.get(
        `OperativeDynamicForm/GetByIdWithAnswers/${operativeDynamicFormId}`,
    );
    commit('setOperativeDinamycFormWithAnswers', data.data);
};

export const getBillsbyActivityId = async ({ commit }, expensesByActivity) => {
    const { data } = await productionApi.get(
        `Expenses/GetOperativeExpensesByActivityId/${expensesByActivity}`,
    );
    commit('setExpensesActivity', data);
    return data.data;
};

export const getExpensesById = async ({ commit }, id) => {
    const { data } = await productionApi.get(`Expenses/${id}`);
    commit('setExpense', data.data);
    return data.data;
};

export const GetOperativeWorksByActivityId = async ({ commit }, id) => {
    const { data } = await productionApi.get(
        `OperativeWorks/GetWorksActivityId/${id}`,
    );
    commit('setOperativeWorks', data.data);
    return data.data;
};

export const putOperativeExpenses = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.put(`Expenses`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data.data;
};

export const getCaseById = async ({ commit }, CaseId) => {
    const { data } = await productionApi.get(`getCaseById/${CaseId}`);
    commit('setCaseVal', data.data);
};

export const getCasesById = async ({ commit }, id) => {
    const { data } = await productionApi.get(`Process/getCasesById/${id}`);
    commit('setCaseVal', data.data);
    return data.data;
};
export const GetAllOperativeWorksByResponsable = async (
    { commit },
    dataToSend,
) => {
    const { data } = await productionApi.post(
        `OperativeWorks/GetAllOperativeWorksByResponsableUserIdArray`,
        dataToSend,
    );
    commit('setWorksByAuth', data.data);
    return data.data;
};

export const createCase = async ({ commit }, data) => {
    await productionApi.post(`Process`, data);
};
export const updateCase = async ({ commit }, dataSend) => {
    const { data } = await productionApi.put(`Process`, dataSend);
    commit('setCaseVal', data);
    return data.data;
};

export const getJobByUserProduction = async ({ commit }, id) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/GetJobByUser/${id}`,
    );
    commit('setJobProduction', data.data);
    return data.data;
};

export const GetWorkflowsByCaseId = async ({ commit }, caseId) => {
    const { data } = await productionApi.get(
        `OperativeWorkFlows/GetWorkflowsByCaseId/${caseId}`,
    );
    commit('dashboard/setlistWorkflows', data.data, { root: true });
};

export const getWorkflowInfoById = async ({ commit }, workflowId) => {
    const { data } = await productionApi.get(
        `OperativeWorkFlows/GetWorkflowSideTab/${workflowId}`,
    );
    commit('setWorkflowInfo', data.data);
    return data.data;
};

export const reOpenActivityDelete = async ({ commit }, id) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingRevision/DeleteActivityReOpen/${id}`,
    );

    return data.data;
};

export const turnOffWorkFlow = async ({ commit }, workflowId) => {
    const { data } = await productionApi.get(
        `OperativeWorkFlows/TurnOffWorkflow/${workflowId}`,
    );
    return data.data;
};

export const turnOffActivityFromWorkflow = async ({ commit }, activityId) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/TurnOffActivityFromWorkflow/${activityId}`,
    );
    return data.data;
};

export const turnOffActivityFromWorkflowCancel = async (
    { commit },
    activityId,
) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/TurnOffActivityFromWorkflowCancel/${activityId}`,
    );
    return data.data;
};

export const turnOffActivity = async ({ commit }, activityId) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/TurnOffActivity/${activityId}`,
    );
    return data.data;
};

export const getFileExport = async ({ commit }, loadOptions) => {
    return productionApi.post(
        `OperativeWorkFlows/GetFileExportLitigios`,
        loadOptions,
    );
};

export const getAllOperativeWorkflowDynamicData = async (
    { commit },
    loadOptions,
) => {
    return productionApi.post(
        `OperativeWorkFlows/GetAllOperativeWorkflowDynamicData`,
        loadOptions,
    );
};

export const getActivitiesByFlow = async ({ commit }, flowId) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/GetActivitiesByFlowId/${flowId}`,
    );
    return data.data;
};

export const getWorkflowsFilteredByFrontParameters = async (
    { commit },
    loadOptions,
) => {
    getWorkflowsFilteredByFrontParametersCounter++;
    getWorkflowsFilteredByFrontParametersController.abort();
    getWorkflowsFilteredByFrontParametersController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeWorkFlows/GetWorkflowsFilteredByFrontParameters`,
            loadOptions,
            {
                signal: getWorkflowsFilteredByFrontParametersController.signal,
            },
        );
        if (
            getWorkflowsFilteredByFrontParametersCounter ===
            getWorkflowsFilteredByFrontParametersCounter
        ) {
            return response;
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return null;
        }
    }
};

export const getCasesFilteredByFrontParameters = async (
    { commit },
    loadOptions,
) => {
    getCasesFilteredByFrontParametersCounter++;
    getCasesFilteredByFrontParametersController.abort();
    getCasesFilteredByFrontParametersController = new AbortController();
    try {
        const response = await productionApi.post(
            `Process/GetCasesFilteredByFrontParameters`,
            loadOptions,
            {
                signal: getCasesFilteredByFrontParametersController.signal,
            },
        );
        if (
            getCasesFilteredByFrontParametersCounter ===
            getCasesFilteredByFrontParametersCounter
        ) {
            return response;
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return null;
        }
    }
};

export const getOldActivitiesFilteredByFrontParameters = async (
    { commit },
    loadOptions,
) => {
    GetOldActivitiesFilteredByFrontParametersCounter++;
    GetOldActivitiesFilteredByFrontParametersController.abort();
    GetOldActivitiesFilteredByFrontParametersController = new AbortController();
    try {
        const response = await productionApi.post(
            `OldActivities/GetOldActivitiesFilteredByFrontParameters/${loadOptions.companyId}`,
            loadOptions,
            {
                signal: GetOldActivitiesFilteredByFrontParametersController.signal,
            },
        );
        if (
            GetOldActivitiesFilteredByFrontParametersCounter ===
            GetOldActivitiesFilteredByFrontParametersCounter
        ) {
            return response;
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return null;
        }
    }
};

export const getOABUFilteredByFrontParameters = async (
    { commit },
    loadOptions,
) => {
    loadOptions = {
        ...loadOptions,
        oportunityStates: parseInt(loadOptions.oportunityStates),
    };
    getOABUFilteredByFrontParametersCounter++;
    getOABUFilteredByFrontParametersController.abort();
    getOABUFilteredByFrontParametersController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/GetOABUFilteredByFrontParameters`,
            loadOptions,
            {
                signal: getOABUFilteredByFrontParametersController.signal,
            },
        );
        if (
            getOABUFilteredByFrontParametersCounter ===
            getOABUFilteredByFrontParametersCounter
        ) {
            return response;
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return null;
        }
    }
};

export const getOABURFilteredByFrontParameters = async (
    { commit },
    loadOptions,
) => {
    loadOptions = {
        ...loadOptions,
        oportunityStates: parseInt(loadOptions.oportunityStates),
    };
    getOABURFilteredByFrontParametersCounter++;
    getOABURFilteredByFrontParametersController.abort();
    getOABURFilteredByFrontParametersController = new AbortController();
    try {
        const response = await productionApi.post(
            `OperativeActivitiesByUsers/GetOABURFilteredByFrontParameters`,
            loadOptions,
            {
                signal: getOABURFilteredByFrontParametersController.signal,
            },
        );
        if (
            getOABURFilteredByFrontParametersCounter ===
            getOABURFilteredByFrontParametersCounter
        ) {
            return response;
        }
    } catch (error) {
        if (error.message !== 'canceled') {
            return null;
        }
    }
};

export const GetFilterWorkGroupsByUserIdRemoved = async (
    { commit, rootState },
    loadOptions,
) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/GetFilterWorkGroupsByUserIdRemoved`,
        loadOptions,
    );
    let currentTeams = rootState.dashboard.teams;
    currentTeams = [...currentTeams, ...data.data];
    localStorage.setItem('teams', JSON.stringify(currentTeams));
    commit('dashboard/setFilterTeams', currentTeams, { root: true });
    return data.data;
};

export const GetParametersMailOutlook = async (
    { commit },
    parametersOptions,
) => {
    const { data } = await productionApi.post(
        `OperativeActivitiesByUsers/GetParametersMailOutlook`,
        parametersOptions,
    );

    return data.data;
};
export const getDefinitionGridReport = async ({ commit }, collectionName) => {
    const { data } = await productionApi.post(
        `OperativeWorkFlows/GetDefinitionGridReport`,
        collectionName,
    );
    commit('setGridReportDef', data.data);
    return data.data;
};

export const getActivitiesByCase = async ({ commit }, loadParams) => {
    return await productionApi.post(`Process/GetActivitiesByCase`, loadParams);
};
export const getWorkflowsByCase = async ({ commit }, loadParams) => {
    return productionApi.post(`Process/GetWorkflowsByCase`, loadParams);
};

export const ValidationShutdownWorkflowsAndActivities = async (
    { commit },
    data,
) => {
    const { idProject, idTradeagreement } = data;
    const response = await productionApi.post(
        `OperativeActivitiesByUsers/ValidationShutdownWorkflowsAndActivities/${idProject}/${idTradeagreement}`,
    );
    return response.data;
};
export const exportActivitiesComplete = async ({ commit }, loadOptions) => {
    return productionApi.post(
        `OperativeActivitiesByUsers/ExportActivitiesComplete`,
        loadOptions,
    );
};

export const getOperativeWorkflowsByTradeAgreement = async (
    { commit },
    tradeAgreementId,
) => {
    const response = await productionApi.get(
        `OperativeWorkFlows/GetOperativeWorkflowsByTradeAgreement/${tradeAgreementId}`,
    );
    return response.data;
};

export const updateActivityByGrid = async ({ commit }, dataToSend) => {
    const response = await productionApi.post(
        `OperativeActivitiesByUsers/UpdateActivityFromGrid`,
        dataToSend,
    );
    return response;
};

export const approveActivityFromGrid = async ({ commit }, dataToSend) => {
    const response = await productionApi.post(
        `OperativeActivitiesByUsers/ApproveActivityFromGrid`,
        dataToSend,
    );
    return response;
};
export const GetDashboardByClientOrNotProd = async ({ commit }, dataToSend) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/GetDashboardByClientOrNot/${dataToSend.companyId}/${dataToSend.idClient}`,
    );
    return data.data;
};

export const GetAllChangesHistoryCitation = async ({ commit }) => {
    const response = await productionApi.get(
        `ChangesHistoryCitation/GetAllChangesHistoryCitation`,
    );
    return response.data;
};

export const GetChangesHistoryCitationById = async ({ commit }, IdActivity) => {
    const response = await productionApi.get(
        `ChangesHistoryCitation/GetChangesHistoryCitationById/${IdActivity}`,
    );
    return response.data;
};

export const UpdateHistoryCitationByIdActivity = async (
    { commit },
    dataConfig,
) => {
    let { IdActivity, IdChangeCitation } = dataConfig;
    const { data } = await productionApi.put(
        `ChangesHistoryCitation/UpdateHistoryCitationByIdActivity/${IdActivity}/${IdChangeCitation}`,
    );
    return data;
};

export const getOperativeActivityByUserIdBucleinActivity = async (
    { commit },
    bucleActivityTF,
) => {
    const { data } = await productionApi.get(
        `OperativeActivitiesByUsers/Bucle/${bucleActivityTF.idBucle}/${bucleActivityTF.idWorkFlow}`,
    );
    return data.data;
};